<template>
  <el-dialog
      title="病历"
      :visible.sync="dialogVisible"
      width="612px"
  >
    <!--医生输入诊费-->
    <div class="main">
      <div>
        <!-- webrtc 推流 -->
        <span
          >webrtc
          推流-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</span
        >
        <br />
        <video id="video" width="600" height="400" autoplay>
          您的浏览器不支持video标签
        </video>
        Audio: <span id="acodecs"></span><br />
        Video: <span id="vcodecs"></span> SessionID: <span id="sessionid"></span>
        <br />
        <span>地址：</span><input type="text" v-model="url" style="width: 300px" />
        <button @click="push">开始推流</button>
        <button @click="startPlay1()">开始播放</button>
        <br />
      
        <span
          >one2one-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</span
        >
        <br />
        <video id="rtc_media_publisher" width="310" autoplay controls></video>
      
        <video id="rtc_media_player" width="310" autoplay controls></video>
      
        <span>服务器地址: </span> <input type="text" v-model="one2one_url" />
        <span>roomId: </span> <input type="text" v-model="one2one_roomId" />
        <span>显示名称: </span> <input type="text" v-model="one2one_name" />
        <span>webrtcurl</span> <span id="self"></span>
        <button @click="startOne2one">开始视频</button>
        <button @click="stopOne2one">离开会议</button>
        <button @click="sendControl">发送控制消息</button>
      </div>
    </div>
 
  </el-dialog>
</template>

 


<script>
export default {
  name: "HelloWorld",
  data() {
    return {
		
		  dialogVisible: true,
      video: null,
      videoObj: { video: { width: 600, height: 400 } },
      sdk: null, 
      //url: "webrtc://meeting.soyuan.com.cn:48038/live/aaa",
      url: "webrtc://webrtc.weimingyl.com:443/live/livestream",
      one2one_url: "8.140.12.83",
      one2one_roomId: "live",
      one2one_name: "live",
      sig: null,
      publisher: null,
      player: null,
    };
  },
  methods: {
	  open(){
	    this.dialogVisible = true
	  },
    async stopOne2one() {
      this.sig.close();
    },
    // 开始一对一视频会议
    async startOne2one() {
      if (this.sig) {
        this.sig.close();
      }

      this.one2one_name = this.one2one_name + this.guid();
      this.sig = new SrsRtcSignalingAsync();
      this.sig.onmessage = (msg) => {
        // 有人离开
        if (msg.event === "leave") {
        }
        // 有人加入
        if (msg.event === "publish") {
          if (
            msg.peer &&
            msg.peer.publishing &&
            msg.peer.display !== this.one2one_name
          ) {
           setTimeout(() => {
              this.startPlay(
              this.one2one_url + ":38000",
              this.one2one_roomId,
              msg.peer.display
            );
           }, 1000);
          }
        }
        // 控制
        if (msg.event === "control") {
        }
      };
      // 链接信令服务器
      await this.sig.connect(
        "wss",
        this.one2one_url + ":" + 9515,
        this.one2one_roomId,
        this.one2one_name
      );

      // 通知加入房间
      let r0 = await this.sig.send({
        action: "join",
        room: this.one2one_roomId,
        display: this.one2one_name,
      });

      // 推流
      await this.startPublish(
        this.one2one_url,
        this.one2one_roomId,
        this.one2one_name
      );

      // 通知开始推流
      let r1 = await this.sig.send({
        action: "publish",
        room: this.one2one_roomId,
        display: this.one2one_name,
      });

      // 检查是否有人已经在会议中
      r0.participants.forEach((participant) => {
        if (
          participant.display === this.one2one_name ||
          !participant.publishing
        )
          return;
        this.startPlay(
          this.one2one_url,
          this.one2one_roomId,
          participant.display
        );
      });
    },
    guid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    // 发送控制消息
    async sendControl() {
      let r1 = await this.sig.send({
        action: "control",
        room: this.one2one_roomId,
        display: this.one2one_name,
        data: "refresh",
      });
    },
    startPlay1() {
      var host = this.url.substring(
        this.url.indexOf("//") + 2,
        this.url.indexOf("/live")
      );
      var room = "live";
      var display = this.url.substring(
        this.url.lastIndexOf("/") + 1,
        this.url.length
      );

      this.startPlay(host, room, display);
    },
    // 开始播放
    startPlay(host, room, display) {
      var url = "webrtc://" + host + "/" + room + "/" + display;
      if (this.player) {
        this.player.close();
      }

      this.player = new SrsRtcPlayerAsync();
      $("#rtc_media_player").prop("srcObject", this.player.stream);
      this.player
        .play(url)
        .then(function (session) {})
        .catch(function (reason) {
          this.player.close();
        });
    },


    // 开始推流
    startPublish(host, room, display) {
      //var url = "webrtc://" + host + "/" + room + "/" + display;
	  var url = "webrtc://8.140.12.83/live/livestream";
      if (this.publisher) {
        this.publisher.close();
      }
      this.publisher = new SrsRtcPublisherAsync();
      $("#rtc_media_publisher").prop("srcObject", this.publisher.stream);

      return this.publisher
        .publish(url)
        .then(function (session) {
          $("#self").text("Self: " + url);
        })
        .catch(function (reason) {
          this.publisher.close();
        });
    },
    push() {
      this.sdk = null;
      if (this.sdk) {
        this.sdk.close();
      }
      this.sdk = new SrsRtcPublisherAsync();
      $("#video").prop("srcObject", this.sdk.stream);
      this.sdk.pc.onicegatheringstatechange = (event) => {
        if (this.sdk.pc.iceGatheringState === "complete") {
          $("#acodecs").html(
            SrsRtcFormatSenders(this.sdk.pc.getSenders(), "audio")
          );
          $("#vcodecs").html(
            SrsRtcFormatSenders(this.sdk.pc.getSenders(), "video")
          );
        }
      };

      this.sdk
        .publish(this.url)
        .then(function (session) {
          $("#sessionid").html(session.sessionid);
        })
        .catch(function (reason) {
          this.sdk.close();
          console.error(reason);
        });
    },
  },
  mounted() {},
};
</script>


